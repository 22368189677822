import dayjs from 'dayjs'
import { IPeriodList } from '../types'
import isBetween from 'dayjs/plugin/isBetween'

import { useEffect, useMemo, useState } from 'react'
import { useMyKDTApplication } from '../queries/useMyKDTApplication'

dayjs.extend(isBetween)

const useMyCurApplication = () => {
  const today = dayjs()
  const { data: myKDTApplication, isLoading: isMyKDTApplicationLoading } = useMyKDTApplication()
  const [isLoading, setIsLoading] = useState(true)
  const curMyKDT = useMemo(
    () =>
      myKDTApplication?.filter(({ applyStartedAt, startedAt }) =>
        today.isBetween(applyStartedAt, startedAt),
      )[0] || {},
    [myKDTApplication],
  )
  const { applies, kdtAttr } = curMyKDT

  const periodList: IPeriodList = {
    assignment: {
      startedAt: dayjs(kdtAttr?.assignmentStartedAt),
      endedAt: dayjs(kdtAttr?.assignmentEndedAt),
    },
    // v3 부터는 참가지원서(participation) 존재하지 않음
    ...(curMyKDT?.apiVersion !== 'v3'
      ? {
          participation: {
            startedAt: dayjs(kdtAttr?.participationStartedAt),
            endedAt: dayjs(kdtAttr?.participationEndedAt),
          },
        }
      : null),
  }

  const activePeriodKey = Object.keys(periodList).find(key => {
    const period = periodList[key]
    return today.isBetween(period.startedAt, period.endedAt)
  })

  const activeApplicationId = useMemo(() => {
    if (activePeriodKey) {
      const activeApply = applies?.find(
        apply => apply.applyType === activePeriodKey && !apply.isFinished,
      )

      return activeApply ? curMyKDT.id : null
    }
    return null
  }, [activePeriodKey, applies])

  const applyUrl = useMemo(() => {
    return curMyKDT?.apiVersion === 'v3'
      ? `${process.env.NEXT_PUBLIC_BASE_URL}/school/${activeApplicationId}/kdt-apply`
      : `${process.env.NEXT_PUBLIC_BASE_URL}/school/${activeApplicationId}/apply`
  }, [curMyKDT, activeApplicationId])

  const activeApplicationDeadline = useMemo(() => {
    if (activePeriodKey) {
      const deadline = periodList[activePeriodKey].endedAt.diff(today, 'day')
      return deadline === 0 ? 'DAY' : deadline
    }
    return null
  }, [periodList, activePeriodKey])

  useEffect(() => {
    if (!isMyKDTApplicationLoading && activeApplicationId !== undefined) {
      setIsLoading(false)
    }
  }, [isMyKDTApplicationLoading, activeApplicationId])

  return {
    applyUrl,
    activeApplicationId,
    activeApplicationDeadline,
    isActiveApplicationIdLoading: isLoading,
  }
}

export default useMyCurApplication
