'use client'

import { useTermsOfServiceQuery } from '@/features/auth/queries/useTermsOfServiceQuery'
import { useAuth } from '@/shared/providers/Auth'
import { ICSSettings } from '@/shared/types/channeltalk'
import ChannelService, {
  detectBrowser,
  detectDeviceType,
  detectOSName,
} from '@/shared/utils/channeltalk'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

const ChannelTalkWrapper = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname()
  const searchParams = useSearchParams().toString()
  const { profile } = useAuth()
  const { data: termsOfService } = useTermsOfServiceQuery()
  const channelService = ChannelService()

  useEffect(() => {
    if (window.ChannelIO) {
      const settings: ICSSettings = {
        pluginKey: process.env.NEXT_PUBLIC_CHANNEL_TALK_KEY as string,
        hideChannelButtonOnBoot: true,
        createdAt: new Date(),
        web: {
          browserName: detectBrowser(navigator),
          osName: detectOSName(navigator),
          device: detectDeviceType(navigator),
        },
      }

      if (profile && termsOfService) {
        const { id, name, email, phoneNo: mobileNumber } = profile
        const { sms, email: marketingAgreementEmail } = termsOfService.marketingAgreement
        settings.memberId = `${id}`
        settings.profile = {
          name,
          email,
          mobileNumber,
          referrer: `${pathname}${searchParams ? `?${searchParams}` : ''}`,
        }
        settings.unsubscribeEmail = marketingAgreementEmail
        settings.unsubscribeTexting = sms
      }

      channelService?.boot(settings)
      channelService?.setpage(window.location.href)
    }
  }, [profile, termsOfService, pathname])

  return children
}

export default ChannelTalkWrapper
