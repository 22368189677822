import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { ITopBanner, ITopBannerResponse } from '../types'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

const fetchTopBanner = async () => {
  const resp = await axios.get<IListDataResp<ITopBannerResponse>>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/showroom/v1/header-banners?filters=displayed_service:bootcamp|status:ing`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const useTopBanner = () => {
  return useQuery<IListDataConvertResp<ITopBanner>>({
    queryKey: ['topBanner'],
    queryFn: () => fetchTopBanner(),
  })
}
