import useMyCurApplication from '@/features/apply/hooks/useMyCurApplication'
import getUrgentKlassList from '@/features/klass/hooks/getUrgentKlassList'
import { LoadingOverlay } from '@mantine/core'

import Image from 'next/image'
import NebecaTestQuickItem from './NebecaTestQuickItem'
import MyApplyingQuickItem from './MyApplyingQuickItem'
import UrgentKlassQuickItem from './UrgentKlassQuickItem'
import ChannelService from '@/shared/utils/channeltalk'

const FloatingSidebar = () => {
  const channelService = ChannelService()
  const { urgentKlassList, isUrgentKlassListLoaidng } = getUrgentKlassList()
  const { applyUrl, activeApplicationId, isActiveApplicationIdLoading } = useMyCurApplication()

  return (
    <>
      {isActiveApplicationIdLoading || isUrgentKlassListLoaidng ? (
        <div className="fixed right-12 top-[50%] z-10 hidden w-[94px] translate-y-[-50%] overflow-hidden rounded-[20px] bg-[rgba(255,255,255,0.8)] shadow-[0px_18.423px_30.704px_0px_rgba(34,34,34,0.10)] backdrop-blur-lg lg:inline-block">
          <LoadingOverlay
            visible={true}
            overlayProps={{ radius: 'sm', backgroundOpacity: 0 }}
            loaderProps={{ color: 'orange', size: 'xs' }}
          />
        </div>
      ) : (
        <div className="fixed right-6 top-[50%] z-10 hidden w-[94px] translate-y-[-50%] overflow-hidden rounded-[20px] bg-[rgba(255,255,255,0.8)] shadow-[0px_18.423px_30.704px_0px_rgba(34,34,34,0.10)] backdrop-blur-lg lg:inline-block">
          <ul className="flex flex-col gap-3 px-2 py-5">
            <li>
              <NebecaTestQuickItem />
            </li>
            {activeApplicationId && (
              <li>
                <MyApplyingQuickItem applyUrl={applyUrl} />
              </li>
            )}
            {!activeApplicationId && urgentKlassList && (
              <li className="space-y-3">
                <UrgentKlassQuickItem klassList={urgentKlassList} />
              </li>
            )}
            <li className="flex flex-col items-center justify-center lg:gap-1.5">
              <button
                onClick={() => channelService?.show()}
                className="relative flex w-full flex-col items-center justify-center"
              >
                <Image
                  alt="채팅상담"
                  className="rounded-2xl"
                  src="https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/follow_bnr_icon_chat.svg"
                  width={42}
                  height={42}
                />
              </button>
              <p className="text-center text-sm font-medium text-[#1E1E1E]">채팅상담</p>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default FloatingSidebar
